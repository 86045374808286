// Import Swiper React components
import {default as MainLayout} from '../../layout/Main'
import {Dialog, DialogTitle, DialogActions, Button, Typography, IconButton, DialogContent, OutlinedInput, makeStyles, CircularProgress, Backdrop} from '@material-ui/core'
import {default as ItemCart} from '../../components/ItemCart'
import {default as GrandTotal} from '../../components/GrandTotal'
import AddToCartButton from '../../components/AddToCartButton'
import { useState, useEffect } from 'react'
import {useHistory} from 'react-router-dom'
import numeral from 'numeral'

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Api from '../../Api'
import config from '../../config'

const Cart = function () {
    const history = useHistory()
    const [daftar, setDaftar] = useState([])
    const [grandTotal, setGrandTotal] = useState(0.0)
    const [dialogUbahPesanan, setDialogUbahPesanan] = useState({open: false, qty: 1})
    const [isBusy, setIsBusy] = useState(false)
    const fetchData = async() =>{
        setIsBusy(true)
        let api = await Api.getCart()
        if(api.ok) {
            let data = api.data || []
            setDaftar(data)
            let total = 0.0
            for(var row of data) {
                row.subtotal = row.qty*row.harga
                total += row.subtotal
            }
            setGrandTotal(total)
        }
        setIsBusy(false)
    }
    
    useEffect(()=>{
        fetchData()
    }, [])

    const handleDeleteClick = (itm)=>{
        return async ()=>{
            if(!window.confirm("Apakah Anda yakin?")) {
                return
            }
            let api = await Api.hapusItemCart(itm)
            if(api.ok) {
                fetchData()
            } else {
                alert('server error')
            }
        }
    }

    const handleEditClick = (itm) => () =>{
        setDialogUbahPesanan({...dialogUbahPesanan, id: itm.id, qty: itm.qty, open: true})
    }

    const handleSimpanPesanan = async()=>{
        let api = await Api.updateItemCart(dialogUbahPesanan.id, dialogUbahPesanan.qty)
        if(api.ok) {
            setDialogUbahPesanan({...dialogUbahPesanan, open: false})
            await fetchData()
        } else {
            alert('server error')
        }
    }

    const handleUpQty = ()=>{
        let q = parseInt(dialogUbahPesanan.qty || 0)
        if(Number.isNaN(q)) {
            q = 0
        }
        q += 1
        setDialogUbahPesanan({...dialogUbahPesanan, qty: q})
    }

    const handleDownQty = ()=>{
        let q = parseInt(dialogUbahPesanan.qty || 1)
        if(Number.isNaN(q)) {
            q = 1
        }
        q -= 1
        setDialogUbahPesanan({...dialogUbahPesanan, qty: q--})
    }

    const RenderItemPesanan = ()=>{
        if(daftar.length > 0) {
            return daftar.map((x)=>{
                return (
                    <ItemCart key={x.id.toString()} item={x} onDeleteClick={handleDeleteClick(x)} onEditClick={handleEditClick(x)}/>
                )
            })
        } else {
            return <div>Oops... daftar masih kosong, klik <a href='/' className='text-blue-900 font-bold'>di sini</a> untuk menambahkan pesanan</div>
        }
    }

    const RenderGrandTotal =() => {
        if(daftar.length > 0) {
            return (
                <>
                    <GrandTotal jumlah={grandTotal}/>
                    <div className="checkout w-full mt-3">
                        <AddToCartButton judul="Checkout" onClick={handleCheckOut}/>
                        <div className='text-center text-blue-900 font-bold'>
                            <div className='mt-3'>
                                Atau
                            </div>
                            <div className='texcheckOutt-center mt-3'>
                                <Button variant='outlined' onClick={()=>{history.push('/')}}>Tambahkan barang lainnya</Button>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return <></>
        }
    }

    const handleCheckOut = ()=>{
        let s = getWhatsAppString()
        window.open('https://wa.me/' + config.whatsAppNo + '?text=' + s)
    }

    const getWhatsAppString = ()=>{
        let newLine = '%0a'
        let lines = []
        lines.push('Halo, saya ingin memesan barang-barang sebagai berikut:')
        for(var row of daftar) {
            lines.push("*" + row.nama.toUpperCase() + '* | ' + row.sku.toUpperCase())
            lines.push("*" + numeral(row.qty).format("0,0") + '* @ ' + numeral(row.harga).format('0,0') + ' = ' + numeral(row.subtotal).format('0,0'))
        }
        lines.push('Total: ' + numeral(grandTotal).format('0,0'))
        lines.push('Mohon konfirmasinya, terima kasih..')
        return lines.map(x=>encodeURIComponent(x)).join(newLine)
    }

    const useStyle = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }))
    const classes = useStyle()

    return (
        <div>
            <Backdrop className={classes.backdrop} open={isBusy}>
                <CircularProgress color='inherit'/>
            </Backdrop>
            <Dialog open={dialogUbahPesanan.open}>
                <DialogTitle>Ubah Pesanan</DialogTitle>
                <DialogContent>
                    <Typography component="h5" variant="h5">Qty</Typography>
                    <div className="flex">
                        <div className="flex-grow-0">
                            <IconButton onClick={handleDownQty}>
                                <RemoveCircleIcon />
                            </IconButton>
                        </div>
                        <div className="flex-grow-1">
                            <OutlinedInput value={dialogUbahPesanan.qty} onChange={(e) => {setDialogUbahPesanan({...dialogUbahPesanan, qty: e.target.value})}}/>
                        </div>
                        <div className="flex-grow-0">
                            <IconButton onClick={handleUpQty}>
                                <AddCircleIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setDialogUbahPesanan({...dialogUbahPesanan, open: false})}}>
                        Batal
                    </Button>
                    <Button onClick={handleSimpanPesanan}>
                        Simpan
                    </Button>
                </DialogActions>
            </Dialog>
            <MainLayout className="container mx-auto p-3 w-full">
                <div className="mt-2">
                    <Typography variant="h5" component="h5">Keranjang</Typography>
                    <p className="mt-2">Berikut ini adalah rincian pemesanan anda. Anda dapat melakukan perubahan pesanan di halaman ini</p>
                    <hr className="divide-solid my-5" />
                    <div className="daftar-produk">
                        <RenderItemPesanan />
                        <RenderGrandTotal />
                    </div>
                </div>
            </MainLayout>
        </div>
    )
}

export default Cart