// Import Swiper React components
import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react'
import 'swiper/swiper.scss';
import ItemBarang from '../../components/ItemBarang';
import {default as MainLayout} from '../../layout/Main'
import AddToCartButton from '../../components/AddToCartButton'
import axios from 'axios'
import numeral from 'numeral'
import Api from '../../Api'
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core'
import Helper from '../../Helper';

let config = require('../../config')


const loadScript = (src) => {
    return new Promise((resolve, reject)=>{
        let script = document.createElement('script')
        script.src=src
        script.async=false
        script.onload=() => {
            resolve('ok')
        }
        document.head.appendChild(script)
    })
}

const loadCss = (href) => {
    let link = document.createElement('link')
    link.href=href
    link.type = 'text/css'
    link.rel = 'stylesheet'
    document.head.appendChild(link)
}

const DetailBarang = function () {
    
    const history = useHistory()
    const [barang, setBarang] = useState({})
    const [daftar, setDaftar] = useState([])
    const [isBusy, setIsBusy] = useState(false)
    const [daftarFoto, setDaftarFoto] = useState([])

    let routeParams = useParams()
    const fetchData = async()=>{
        setIsBusy(true)
        let res = await axios.post(config.baseUrl + 'detailbarang/' + routeParams.id)
        let api = res.data
        if(api.ok) {
            if(api.data) {
                if(api.data.barang) {
                    api.data.barang.hargaPromo = Helper.getHargaPromo(api.data.barang, api.data.daftarPromo)
                }
                setBarang(api.data.barang)
                setDaftar(api.data.daftarBarang)
                setDaftarFoto(api.data.daftarFoto)
            }
        } else {
            alert('server error')
        }
        setIsBusy(false)
    }
    useEffect(()=>{
        (async ()=>{
            await fetchData()
            await loadCss('/vendor/lightslider/css/lightslider.min.css')
            await loadScript('/vendor/lightslider/js/lightslider.min.js')
            await loadCss('/vendor/lightgallery/css/lightgallery.min.css')
            await loadScript('/vendor/lightgallery/js/lightgallery.min.js')
            await loadScript('/vendor/lightgallery/js/lightgallery.min.js')
            await loadScript('/vendor/agus/detailBarang.js')
        })()
    }, [])

    const sendData = (itm) => async() => {
        setIsBusy(true)
        let api = await Api.addToCart(itm, 1)
        if(api.ok) {
            alert('item telah ditambahkan')
        } else {
            alert('server error')
        }
        setIsBusy(false)
    }

    const useStyle = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }))
    const classes = useStyle()

    return (
        <MainLayout className="container mx-auto p-3 w-full">
            <Backdrop className={classes.backdrop} open={isBusy}>
                <CircularProgress color='inherit'/>
            </Backdrop>
            <div className="detail-barang">
                <div className="foto-barang mx-auto">
                    <ul id='imageGallery'>
                        {
                            daftarFoto.map(x=>(
                                <li key={x.id} data-thumb={(config.baseUrlThumb2 + x.id)} data-src={(config.baseUrlFoto + x.id)}>
                                    <img src={(config.baseUrlThumb2 + x.id)} className='w-full mx-auto' alt='foto'/>
                                </li>
                            ))
                        }
                    </ul>
                    {/* <img src={config.baseUrlThumb2 + barang.fotoid} className="rounded-lg mx-auto"/> */}
                </div>
                <div className='mt-3 text-center uppercase text-lg'>
                    {
                        barang.nama
                    }
                </div>
                <div className='grid grid-cols-2 mt-3'>
                    <div>SKU</div>
                    <div className='uppercase'>{barang.sku}</div>
                    <div>Kategori</div>
                    <div className='uppercase'>{barang.kategori}</div>
                    <div>Brand</div>
                    <div className='uppercase'>{barang.brand}</div>
                    {/* <div>Harga</div>
                    <div className='text-lg text-pink-500 font-bold'>Rp {numeral(barang.harga).format('0,0')}</div> */}
                    {
                        barang.promo ? (
                            <>
                                <div>Harga</div>
                                <div className='text-lg text-gray-500 line-through'>Rp {numeral(barang.harga).format('0,0')}</div>
                                <div>Harga Promo</div>
                                <div className='text-lg text-pink-500 font-bold'>Rp {numeral(barang.hargaPromo).format('0,0')}</div>
                            </>
                        ) : (
                            <>
                                <div>Harga</div>
                                <div className='text-lg text-pink-500 font-bold'>Rp {numeral(barang.harga).format('0,0')}</div>
                            </>
                        )
                    }
                </div>
                <div className="text-sm mt-2">
                    <div dangerouslySetInnerHTML={{__html: barang.deskripsiweb}} />
                </div>
                <div className='mt-3'>
                    <AddToCartButton disabled={parseInt(barang.stok || 0) <= 0} judul='Tambahkan' onClick={sendData(barang)}/>
                </div>
            </div>
            <div className="barang-lainnya mt-3">
                <strong>Produk Lainnya</strong>
                <div className="grid grid-cols-2">
                    {
                        daftar.map(x=>{
                            return <ItemBarang key={x.id.toString()} barang={x} onItemClick={()=>{history.push('/detail/' + x.id)}} onCartClick={sendData(x)} />
                        })
                    }
                </div>
            </div>
        </MainLayout>
    )
}

export default DetailBarang