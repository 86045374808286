const baseUrl = 'https://padmana.sawopos.com/shop/'
// const baseUrl = 'http://localhost:3000/shop/'
let config = {
    baseUrl: baseUrl,
    baseUrlFoto: baseUrl + 'foto/',
    baseUrlThumb1: baseUrl + 'thumb1/',
    baseUrlThumb2: baseUrl + 'thumb2/',
    baseUrlBanner: baseUrl + 'fotobanner/',
    whatsAppNo: '6281338671227'
}

module.exports = config