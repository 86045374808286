// Import Swiper React components
import {default as MainLayout} from '../../layout/Main'
import Api from '../../Api'
import { useEffect, useState } from 'react'

const Kontak = function () {
    const [daftarFooter, setDaftarFooter] = useState([])

    useEffect(() => {
        (async() => {
            let api = await Api.getDaftarFooter()
            if(api.ok) {
                setDaftarFooter(api.data)
            }
        })()
    }, [])

    return (
        <MainLayout className="container mx-auto p-3 w-full">
            <h1>Kontak</h1>    
            <div>
                {
                    daftarFooter.map((x, idx)=> {
                        return (
                            <div key={idx} dangerouslySetInnerHTML={{
                                __html: x.isi
                            }}>
                            </div>
                        )
                    })
                }
            </div>
        </MainLayout>
    )
}

export default Kontak