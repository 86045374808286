import {Link} from 'react-router-dom'
import AddToCartButton from './AddToCartButton'
import numeral from 'numeral'
import PropTypes from 'prop-types'

let config = require('../config')

const ItemBarang = function (props) {
    let b = props.barang

    const renderHarga = (itm) => {
        if(itm.promo) {
            return <span><span className='line-through text-sm text-gray-500'>Rp {numeral(b.harga).format("0,0")}</span> <br /><span className='text-blue-900'>Rp {numeral(b.hargaPromo).format("0,0")}</span></span>
        } else {
            return <spam className='text-blue-900'>Rp {numeral(b.harga).format("0,0")} <br /></spam>
        }
        // return <span className='text-blue-900'>Rp {numeral(b.harga).format("0,0")} <br /></span>
    }

    const renderReady = (itm) => {
        if(itm.stok > 0) {
            return <span className='text-xs text-green-700'><i className='fa fa-tag mr-1'/> Ready</span>
        } else {
            return <span className='text-xs text-gray-500'>Stok Habis <i className='fa fa-exclamation ml-1'/></span>
        }
    }

    const renderPromo = (itm) => {
        if(itm.promo) {
            return <span className='text-xs text-red-700 ml-2'><i className='fa fa-tag mr-1'/> Promo</span>
        } else {
            return <></>
        }
    }
   
    return (
        <div className="produk mx-auto mt-4" style={{width: '150px'}}>
            <a href={'/detail/'+b.id}>
                <div className="foto-produk overflow-hidden" style={{height: '150px'}}>
                    {
                        (
                            <img src={config.baseUrlThumb1 + b.fotoid} className="rounded-lg object-fit"/>
                        )
                    }
                </div>
                <div className="deskripsi uppercase overflow-ellipsis overflow-hidden font-sans text-sm p-1" style={{height: '4rem'}}>
                    {b.nama}
                </div>
                <div className="deskripsi font-sans font-bold text-center">
                    {renderHarga(b)}
                </div>
                <div className='tags'>
                    {renderReady(b)}
                    {renderPromo(b)}
                </div>
            </a>
            <div className="mt-2" />
            <AddToCartButton judul="Tambahkan" disabled={parseInt(b.stok || 0) <= 0} onClick={()=>{props.onCartClick && props.onCartClick()}}/>
        </div>
    )
}

ItemBarang.defaultProps = {
    barang: {}
}

ItemBarang.propTypes = {
    barang: PropTypes.object,
}
export default ItemBarang