import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {Button} from '@material-ui/core'
const AddToCartButton = function (props) {
    return (
        <div className='mx-auto text-center'>
            <Button variant='outlined' disabled={props.disabled} onClick={()=>{props.onClick && props.onClick()}}>
                <ShoppingCartIcon />
                <span className="ml-3">{props.judul}</span>
            </Button>
        </div>
    )
}

export default AddToCartButton