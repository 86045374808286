import './App.css';
import {Home, DetailBarang, Cart, Kontak, DaftarBarangPromo, SyaratKetentuan} from './views'
import {BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom'
import { useEffect } from 'react';
import axios from 'axios'
import Api from './Api'

let config = require("./config")
function App() {
  useEffect(()=>{
    let token = sessionStorage.getItem('token') || ''
    if(token.length == 0) {
      const fetchData = async() =>{
        let res = await axios.post(config.baseUrl + 'login')
        let api = res.data
        if(api.ok) {
          let token = api.data
          sessionStorage.setItem('token', token)
          Api.token = token
        }
      }
      fetchData()
    }
  }, [])
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <RenderHome />
          </Route>
          <Route exact path="/daftarbarangpromo">
            <RenderDaftarBarangPromo />
          </Route>
          <Route path="/detail/:id">
            <RenderDetailBarang />
          </Route>
          <Route path="/cart">
            <Cart />
          </Route>
          <Route path="/kontak">
            <Kontak />
          </Route>
          <Route path="/syarat_ketentuan">
            <SyaratKetentuan />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

const RenderHome = ()=>{
  return (
    <Home />
  )
}

const RenderDaftarBarangPromo = ()=>{
  return (
    <DaftarBarangPromo />
  )
}

const RenderDetailBarang = ()=>{
  return (
    <DetailBarang />
  )
}
export default App;
