import { useEffect, useState } from 'react'
import { default as MainLayout } from '../../layout/Main'
import Api from '../../Api'
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core'

export default function () {
    const [isi, setIsi] = useState('')
    const [nama, setNama] = useState('')
    const [isBusy, setIsBusy] = useState(false)

    const fetchData = async () => {
        setIsBusy(true)
        let api = await Api.getSyaratKetentuan()
        if (api.ok) {
            let daftar = api.data
            if (daftar.length > 0) {
                let itm = daftar[0]
                setNama(itm.nama)
                setIsi(itm.isi)
            } else {
                setIsi('')
                setNama('')
            }
        } else {
            alert('server error.\n' + api.msg)
        }
        setIsBusy(false)
    }

    useEffect(fetchData, [])

    const useStyle = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }))

    const classes = useStyle()

    return (
        <MainLayout className="container mx-auto p-3 w-full">
            <Backdrop className={classes.backdrop} open={isBusy}>
                <CircularProgress color='inherit' />
            </Backdrop>
            <div className="syarat_ketentuan">
                <div className='mt-3 text-center uppercase text-lg'>
                    {nama}
                </div>
                <div className="text-sm mt-2">
                    <div dangerouslySetInnerHTML={{ __html: isi }} />
                </div>
            </div>
        </MainLayout>
    )
}
