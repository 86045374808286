import { Link, useHistory } from 'react-router-dom'
import Logo1 from '../aset/img/logo1.jpg'
import Logo2 from '../aset/img/logo2.jpg'
import { Toolbar, Fab, AppBar, List, ListItem, ListItemIcon, makeStyles, SwipeableDrawer, ListItemText, InputBase } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { useEffect, useState } from 'react'
import clsx from 'clsx'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PhoneIcon from '@material-ui/icons/Phone';
import { ShoppingCartOutlined } from '@material-ui/icons'
import HighlightIcon from '@material-ui/icons/Highlight';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import Api from '../Api'

const useStyles = makeStyles((theme) => ({
    list: {
        width: 350,
        paddingLeft: 10,
        paddingRight: 10
    },
    appBar: {
        backgroundColor: 'white',
        border: 'black 1px',
        color: 'black'
    },
    container: {
        maxWidth: '550px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '12px',
        border: '1px solid lightgrey',
        borderRadius: 10
    },
    fab: {
        position: 'fixed',
        top: 'auto',
        left: 'auto',
        right: 50,
        bottom: 50,
        zIndex: theme.zIndex.modal
    }
}));

export default function (props) {
    const history = useHistory()
    const classes = useStyles()
    const [state, setState] = useState({ drawerOpen: false })
    const toggleDrawer = (open) => (event) => {
        setState({ ...state, drawerOpen: open })
    }

    return (
        <div className="container mx-auto p-3">
            <div className={clsx(classes.container)}>
                <Fab variant='circular' color='primary' className={clsx(classes.fab)} onClick={() => { history.push('/cart') }}>
                    <ShoppingCartOutlined />
                </Fab>
                <AppBar position='sticky' className={clsx(classes.appBar)}>
                    <Toolbar>
                        <MenuIcon onClick={toggleDrawer(true)} />
                        <div className="mx-auto" style={{ width: 125 }}>
                            <div>
                                <Link to='/'>
                                    <img src={Logo2} className="object-cover" />
                                </Link>
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                <SwipeableDrawer
                    anchor={'left'}
                    open={state['drawerOpen']}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <div className={clsx(classes.list)}>
                        <List>
                            <ListItem>
                                <div>
                                    <Link to='/'>
                                        <img src={Logo1} className="object-cover" />
                                    </Link>
                                </div>
                            </ListItem>
                            <ListItem button component={Link} to="/">
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText>Beranda</ListItemText>
                            </ListItem>
                            <ListItem button component={Link} to="/daftarbarangpromo">
                                <ListItemIcon>
                                    <HighlightIcon />
                                </ListItemIcon>
                                <ListItemText>Promosi</ListItemText>
                            </ListItem>
                            <ListItem button component={Link} to="/cart">
                                <ListItemIcon>
                                    <ShoppingBasketIcon />
                                </ListItemIcon>
                                <ListItemText>Keranjang</ListItemText>
                            </ListItem>
                            <ListItem button component={Link} to="/kontak">
                                <ListItemIcon>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <ListItemText>Contact Us</ListItemText>
                            </ListItem>
                            <ListItem button component={Link} to='/syarat_ketentuan'>
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText>Syarat dan Ketentuan</ListItemText>
                            </ListItem>
                        </List>
                    </div>
                </SwipeableDrawer>
                <div className="mt-3" />
                {props.children}
            </div>
        </div>
    )
}