import {IconButton} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import numeral from 'numeral'
import PropTypes from 'prop-types'
import config from '../config';

const ItemCart = function(props) {
    let det = props.item
    const handleDeleteClick = ()=>{
        props.onDeleteClick && props.onDeleteClick()
    }
    const handleEditClick = () => {
        props.onEditClick && props.onEditClick()
    }
    return (
        <div>
            <div className="item flex">
                <div className="item-image rounded-lg mx-auto flex-grow-0 px-2">
                    <img src={config.baseUrl + 'foto/' + det.fotoid} className="object-fit rounded-lg" style={{width: 120}}/>
                </div>
                <div className="flex-grow flex flex-col">
                    <div className="flex-grow flex">
                        <div className="detail flex-grow flex">
                            <div>
                                <p className='uppercase'><a href={'/detail/' + det.barangid}>{det.nama}</a></p>
                                <p className="font-mono">{det.sku}</p>
                                <div className="flex">
                                    {numeral(det.qty).format("0,0")} x @ Rp {numeral(det.harga).format("0,0")}
                                </div>
                            </div>
                        </div>
                        <div className="subtotal flex-grow-0 flex flex-col">
                            <div className="flex-grow-0 text-center">Rp</div>
                            <div className="flex-grow text-center align-middle text-green-700 font-bold text-lg">{numeral(det.qty*det.harga).format("0,0")}</div>
                        </div>
                    </div>
                    <div className="flex-grow-0 action text-right">
                        <IconButton onClick={handleDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton onClick={handleEditClick}>
                            <EditIcon />
                        </IconButton>
                    </div>
                </div> 
            </div>
            <hr className="divide-solid my-3"/>
        </div>
    )
}

ItemCart.defaultProps = {
    item: {}
}

ItemCart.propTypes = {
    item: PropTypes.object,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func
}
export default ItemCart