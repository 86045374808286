import _ from 'lodash'

class Helper {
    static getHargaPromo(barang, daftarPromo) {
        let hargaPromo = barang.harga
        // kategori
        let daftarPromoKategori = _.orderBy(_.filter(daftarPromo.daftarKategori, { 'kategoriid': barang.kategoriid}), ["prioritas"])
        let promoKategori = daftarPromoKategori[0]
        if(promoKategori) {
            let promo = daftarPromo.daftarPromo.filter(x=>x.id == promoKategori.promoid)[0]
            if(promo) {
                promo.disc = promo.disc || 0.0
                promo.potongan = promo.potongan || 0.0
                if(promo.disc > 0) {
                    hargaPromo = barang.harga * (1-promo.disc/100)
                } else {
                    hargaPromo = barang.harga - promo.potongan
                }
            }
        }
        // brand
        let daftarPromoBrand = _.orderBy(_.filter(daftarPromo.daftarBrand, { 'brandid': barang.brandid}), ["prioritas"])
        let promoBrand = daftarPromoBrand[0]
        if(promoBrand) {
            let promo = daftarPromo.daftarPromo.filter(x=>x.id == promoBrand.promoid)[0]
            if(promo) {
                promo.disc = promo.disc || 0.0
                promo.potongan = promo.potongan || 0.0
                if(promo.disc > 0) {
                    hargaPromo = barang.harga * (1-promo.disc/100)
                } else {
                    hargaPromo = barang.harga - promo.potongan
                }
            }
        }

        // barang
        let daftarPromoBarang = _.orderBy(_.filter(daftarPromo.daftarBarang, { 'barangid': barang.id}), ["prioritas"])
        let promoBarang = daftarPromoBarang[0]
        if(promoBarang) {
            let promo = daftarPromo.daftarPromo.filter(x=>x.id == promoBarang.promoid)[0]
            if(promo) {
                promo.disc = promo.disc || 0.0
                promo.potongan = promo.potongan || 0.0
                if(promo.disc > 0) {
                    hargaPromo = barang.harga * (1-promo.disc/100)
                } else {
                    hargaPromo = barang.harga - promo.potongan
                }
            }
        }
        // departemen
        let daftarPromoDepartemen = _.orderBy(_.filter(daftarPromo.daftarDepartemen, { 'departemenid': barang.departemenid}), ["prioritas"])
        let promoDepartemen = daftarPromoDepartemen[0]
        if(promoDepartemen) {
            let promo = daftarPromo.daftarPromo.filter(x=>x.id == promoDepartemen.promoid)[0]
            if(promo) {
                promo.disc = promo.disc || 0.0
                promo.potongan = promo.potongan || 0.0
                if(promo.disc > 0) {
                    hargaPromo = barang.harga * (1-promo.disc/100)
                } else {
                    hargaPromo = barang.harga - promo.potongan
                }
            }
        }
        return hargaPromo
    }
}

export default Helper