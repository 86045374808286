import axios from 'axios'

let config = require('./config')
class Api {
    static token = sessionStorage.getItem('token') || ''

    static async getHomeData(param) {
        const res = await axios.post(
            config.baseUrl + 'home',
            param,
        );
        let api = res.data
        return api
    }

    static getBannerUrl(id) {
        return config.baseUrlBanner + id
    }

    static async getFooter(id) {
        const res = await axios.post(
            config.baseUrl + 'footer/' + id,
        );
        let api = res.data
        return api
    }

    static async getSyaratKetentuan() {
        const res = await axios.post(
            config.baseUrl + 'artikel',
        );
        let api = res.data
        return api
    }

    static async daftarFooter() {
        const res = await axios.post(
            config.baseUrl + 'footers',
        );
        let api = res.data
        return api
    }

    static async getDaftarBarangPromo(param) {
        const res = await axios.post(
            config.baseUrl + 'daftarbarangpromo',
            param,
        );
        let api = res.data
        return api
    }

    static async getDaftarFooter() {
        let api = {
            ok: false,
            msg: ''
        }
        let res = await axios.post(config.baseUrl + 'footers')
        api = res.data
        return api
    }

    static async getDaftarPromo(param) {
        const res = await axios.post(
            config.baseUrl + 'daftarpromo',
            param,
        );
        let api = res.data
        return api
    }

    static async getCart() {
        let res = await axios.post(config.baseUrl + 'cart', {}, {
            headers: {
                token: Api.token
            }
        })
        let api = res.data
        return api
    }

    static async addToCart(barang, qty = 1) {
        let res = await axios.post(config.baseUrl + 'addtocart', {barangid: barang.id, qty}, {
            headers: {
                token: Api.token
            }
        })
        let api = res.data
        return api
    }

    static async updateItemCart(id, qty) {
        let res = await axios.post(config.baseUrl + 'updateitemcart', {id, qty}, {
            headers: {
                token: Api.token
            }
        })
        let api = res.data
        return api
    }

    static async hapusItemCart(itm) {
        let res = await axios.post(config.baseUrl + 'hapusitemcart', {id: itm.id}, {
            headers: {
                token: Api.token
            }
        })
        let api = res.data
        return api
    }
}

export default Api