import {default as MainLayout} from '../../layout/Main'
import React, {useState} from 'react'
import {Backdrop, IconButton, InputAdornment, CircularProgress, OutlinedInput, TextField, makeStyles} from '@material-ui/core'
import {Autocomplete, Pagination} from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'
import ItemBarang from '../../components/ItemBarang'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Api from '../../Api'
import Helper from '../../Helper'
let config = require("../../config")

const Home = function () {
    const history = useHistory()
    const [state, setState] = useState({daftarBrand:[], daftarKategori: [], daftarBarang: [], busy: false})
    const [cari, setCari] = useState('')
    const [selectedKategori, setSelectedKategori] = useState(null)
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [totalPages, setTotalPages] = useState(1)
    const [page, setPage] = useState(1)
    const [isBusy, setIsBusy] = useState(false)
    
    const fetchData = async ()=>{
        let kategoriId = -1, brandId = -1, skip=(page-1)*20, take=20
        if(selectedKategori) {
            kategoriId = selectedKategori.id
        }
        if(selectedBrand) {
            brandId = selectedBrand.id
        }
        let param = {
            cari: cari,
            kategoriid: kategoriId,
            brandid: brandId,
            skip: skip,
            take: take
        }
        setIsBusy(true)
        let api = await Api.getDaftarBarangPromo(param)
        if(api.ok) {
            // console.dir(api.data.daftarBarang)
            let daftarPromo = api.data.daftarPromo || []
            for(var barang of api.data.daftarBarang) {
                let hargaPromo = Helper.getHargaPromo(barang, daftarPromo)
                barang.hargaPromo = hargaPromo
            }
            setState({...state, ...api.data});
            let temp = Math.ceil(api.data.totalCount/20)
            setTotalPages(temp)
            console.dir(api.data)
        } else {
            alert("server error")
        }
        setIsBusy(false)
    }

    useEffect(() => {
        fetchData()
    }, [selectedBrand, selectedKategori, page]);

    const sendData = (itm) => async() => {
        setIsBusy(true)
        let api = await Api.addToCart(itm, 1)
        if(api.ok) {
            alert('item telah ditambahkan')
        } else {
            alert('server error')
        }
        setIsBusy(false)
    }

    const useStyle = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    }))
    const classes = useStyle()
    return (
        <MainLayout className="container mx-auto p-3">
            <Backdrop className={classes.backdrop} open={isBusy}>
                <CircularProgress color='inherit'/>
            </Backdrop>
            <OutlinedInput className="w-full" placeholder="Cari Nama Barang" value={cari} onChange={(e, value)=>{setCari(value)}} endAdornment={<InputAdornment position="end"><IconButton onClick={()=>{
                    setPage(1)
                    fetchData()
                }}><SearchIcon /></IconButton></InputAdornment>} variant="outlined" value={cari} onChange={(e)=>{setCari(e.target.value)}}/>
            <div className="filter-pencarian">
                <div className="filter-brand w-full">
                    <p>Brand</p>
                    <Autocomplete
                        id="cbo-brand"
                        options={state.daftarBrand}
                        getOptionLabel={(option) => option.nama}
                        className="w-full"
                        value={selectedBrand}
                        onChange={(e, value)=>{
                            setPage(1)
                            setSelectedBrand(value)
                        }}
                        renderInput={(params) => <TextField {...params} placeholder='Pilih Brand' variant="outlined" />}
                        getOptionSelected={(option, value)=>{ return option.id == value.id}}
                    />
                    
                </div>
                <div className="mt-2" />
                <div className="filter-kategori w-full">
                    <p>Kategori</p>
                    <Autocomplete
                        id="cbo-kategori"
                        options={state.daftarKategori}
                        getOptionLabel={(option) => option.nama}
                        className="w-full"
                        value={selectedKategori}
                        onChange={(e, value)=>{
                            setPage(1)
                            setSelectedKategori(value)
                        }}
                        renderInput={(params) => <TextField {...params} placeholder='Pilih Kategori Barang' variant="outlined" />}
                        getOptionSelected={(option, value)=>{ return option.id == value.id}}
                    />
                </div>
            </div>
            <div className="mt-2" />
            <div className="daftar-produk mx-auto w-100 grid grid-cols-2">
                {
                    state.daftarBarang.map(x=>{
                        return <ItemBarang key={x.id.toString()} barang={x} onItemClick={()=>{history.push('/detail/' + x.id)}} onCartClick={sendData(x)} />
                    })
                }
            </div>
            <div className={(totalPages <= 1 ? 'hidden' : '') + ' mt-2'}>
                <Pagination count={totalPages} page={page} onChange={(e, value)=>{setPage(value)}} variant='outlined' shape='rounded'/>
            </div>
        </MainLayout>
    )
}

export default Home